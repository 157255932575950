export class UpdateRecommendationDto {
  constructor(recommendation) {
    this.analystName = recommendation.analystName;
    this.analystCompany = recommendation.analystCompany;
    this.ticker = recommendation.ticker;
    this.priceTarget = recommendation.priceTarget;
    this.recommendation = recommendation.recommendation.toLowerCase();
    this.date = recommendation.date;
  }
}
