<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" md="4" lg="3">
        <v-select
          :value="params.type"
          :items="types"
          label="Ошибка"
          item-value="value"
          item-text="title"
          clearable
          @input="$emit('change-field', $event)"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      params: { ...this.tableParams },
      types: [
        { value: "no_analyst", title: "Нет аналитика" },
        { value: "expert_twin_found", title: "Дубль аналитика" }
      ]
    };
  }
};
</script>
