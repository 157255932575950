<template>
  <v-dialog :value="isOpen" max-width="500px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <div class="text-h6 mb-3">Подтверждение удаления</div>
      </v-card-title>

      <v-card-subtitle> {{ subtitle }} </v-card-subtitle>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')">Нет, отменить</v-btn>
        <v-btn color="primary" @click="$emit('delete')">Да, удалить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    subtitle: {
      type: String,
      default: "Вы уверены, что хотите удалить прогноз с ошибкой?"
    }
  }
};
</script>
