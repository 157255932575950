<template>
  <v-dialog :value="true" max-width="500px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h6">Редактирование рекомендации</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :value="recommendation.analystName"
                label="Аналитик"
                @input="setField('analystName', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                :value="recommendation.analystCompany"
                label="Компания"
                @input="setField('analystCompany', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field :value="recommendation.ticker" label="Тикер" @input="setField('ticker', $event)" />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="recommendation.recommendation"
                :items="recommendationsTypes"
                label="Рекомендация"
                item-value="uid"
                item-text="text"
                required
                @input="setField('recommendation', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="priceTarget" label="Прог. цена" />
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker :value="recommendation.date" label="Дата" @input="setField('date', $event)" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')">Отмена</v-btn>
        <v-btn color="blue darken-1" text @click="$emit('update')">Обновить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    recommendation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      recommendationsTypes: [
        { uid: "Buy", text: "Покупать" },
        { uid: "Hold", text: "Держать" },
        { uid: "Sell", text: "Продавать" }
      ]
    };
  },
  computed: {
    priceTarget: {
      get() {
        return this.recommendation.priceTarget;
      },
      set(value) {
        this.setField("priceTarget", value.replace(",", "."));
      }
    }
  }
};
</script>
