<template>
  <div>
    <h2 class="mb-4">Рекомендации с ошибками</h2>

    <recommendations-filters :table-params="tableParams" @change-field="changeField" />

    <data-table ref="dataTable" url="stock-rating-feed" :table-params="tableParams" :headers="headers">
      <template v-slot:[`item.analystName`]="{ item: recommendation }">
        {{ recommendation.analystName }}
      </template>

      <template v-slot:[`item.ticker`]="{ item: recommendation }">
        {{ recommendation.ticker }}
      </template>

      <template v-slot:[`item.analystCompany`]="{ item: recommendation }">
        {{ recommendation.analystCompany }}
      </template>

      <template v-slot:[`item.recommendation`]="{ item: recommendation }">
        {{ recommendation.recommendation | consensus }}
      </template>

      <template v-slot:[`item.priceTarget`]="{ item: recommendation }">
        {{ recommendation.priceTarget | number }}
      </template>

      <template v-slot:[`item.date`]="{ item: recommendation }">
        {{ recommendation.date | date }}
      </template>

      <template v-slot:[`item.type`]="{ item: recommendation }">
        <div class="d-inline-flex align-center pa-1">
          <v-img :src="`/img/recommendation/${recommendation.type}.png`" width="26" height="26" contain />
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item: recommendation }">
        <div class="d-flex align-center">
          <v-btn color="primary" small @click="importRecommendation(recommendation.id)"> Импортировать </v-btn>

          <v-btn class="ml-3" icon color="accent" @click="openEditModal(recommendation)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn class="ml-3" icon color="error" @click="confirmDelete(recommendation)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table>

    <recommendations-confirm-delete
      :is-open="paramsConfirm.isOpen"
      @close="paramsConfirm.isOpen = false"
      @delete="deleteRecommendation(paramsConfirm.recommendationId)"
    />

    <recommendations-edit
      v-if="activeRecommendation !== null"
      :recommendation="activeRecommendation"
      @change-field="changeRecommendationField"
      @close="resetRecommendationFields"
      @update="updateRecommendation"
    />
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { UpdateRecommendationDto } from "~/libs/recommendation/mistakes/update-recommendation-dto";

import RecommendationsFilters from "~/components/recommendations/mistakes/filters";
import RecommendationsConfirmDelete from "~/components/recommendations/confirm-delete";
import RecommendationsEdit from "~/components/recommendations/mistakes/edit";

export default {
  data() {
    return {
      headers: [
        { text: "Аналитик", value: "analystName", sortable: true },
        { text: "Компания", value: "analystCompany", sortable: true },
        { text: "Тикер", value: "ticker" },
        { text: "Рекомендация", value: "recommendation", sortable: true },
        { text: "Прог. цена", value: "priceTarget", sortable: true },
        { text: "Дата", value: "date", sortable: true },
        { text: "Ошибка", value: "type", sortable: true },
        { text: "Действия", value: "actions", sortable: false }
      ],
      tableParams: {},
      paramsConfirm: {
        recommendationId: null,
        isOpen: false
      },
      activeRecommendation: null
    };
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    },
    async deleteRecommendation(id) {
      try {
        await this.$axios.$delete(`stock-rating-feed/${id}`);

        await this.$refs.dataTable.update();

        this.$snackbar.success("Удалено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось удалить: " + html);
      } finally {
        this.paramsConfirm.isOpen = false;
      }
    },
    async importRecommendation(id) {
      try {
        await this.$axios.$post(`stock-rating-feed/${id}`);

        await this.$refs.dataTable.update();

        this.$snackbar.success("Импортировано");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось импортировать: " + html);
      }
    },
    async updateRecommendation() {
      try {
        await this.$axios.$put(
          `stock-rating-feed/${this.activeRecommendation.id}`,
          new UpdateRecommendationDto(this.activeRecommendation)
        );

        await this.$refs.dataTable.update();

        this.$snackbar.success("Рекомендация изменена");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось изменить рекомендацию: " + html);
      } finally {
        this.activeRecommendation = null;
      }
    },
    confirmDelete(recommendation) {
      if (recommendation.type !== "possible_double") {
        this.paramsConfirm.recommendationId = recommendation.id;

        this.paramsConfirm.isOpen = true;
      } else {
        this.deleteRecommendation(recommendation.id);
      }
    },
    openEditModal(recommendation) {
      this.activeRecommendation = { ...recommendation };
    },
    changeField(type) {
      if (type === null) {
        this.tableParams = {};
      } else {
        this.tableParams = { type };
      }
    },
    changeRecommendationField({ field, value }) {
      this.activeRecommendation[field] = value;
    },
    resetRecommendationFields() {
      this.activeRecommendation = null;
    }
  },
  components: {
    RecommendationsFilters,
    RecommendationsConfirmDelete,
    RecommendationsEdit
  }
};
</script>
~/libs/recommendation/mistakes/update-recommendation-dto
